/**
 * Created by Administrator on 2018/7/1.
 */
import React,{Component} from 'react';
import Loading from './loading'
export default class Bundle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mod: null
        };
    }

    componentDidMount() {
        this.load(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.load !== this.props.load) {
            this.load(nextProps)
        }
    }

    load(props) {
        this.setState({
            mod: null
        });
        //注意这里，使用Promise对象; mod.default导出默认
        if(props.load !== null){
            props.load().then((mod) => {
                this.setState({
                    mod: mod.default ? mod.default : mod
                });
            });
        }
    }

    render() {
        return this.state.mod ? this.props.children(this.state.mod) : <Loading/>;
    }
}