export const CLEAR_KLINE = 'TRADE/CLEAR_KLINE' //清除K线数据

export const CHANGE_STOCK_DATA = 'TRADE/CHANGE_STOCK_DATA'  //K线数据

export const CHANGE_QUOTE_DATA = 'TRADE/CHANGE_QUOTE_DATA' //行情数据

export const CHANGE_MARKET = 'TRADE/CHANGE_MARKET' //切换市场

export const DEFAULT_ACCOUNT = 'TRADE/DEFAULT_ACCOUNT'  //默认账户

export const CHANGE_CODE = 'TRADE/CHANGE_CODE'

export const CHANGE_PERIOD = 'TRADE/CHANGE_PERIOD'  //切换周期

export const CHANGE_LAST_KLINE = 'TRADE/CHANGE_LAST_KLINE'

export const CHANGE_UUID = 'TRADE/CHANGE_UUID'

export const SET_PROFIT_LINE = 'TRADE/SET_PROFIT_LINE'

export const CLEAR_PROFIT_LINE = 'TRADE/CLEAR_PROFIT_LINE'

export const CHANGE_DIRECTION = 'TRADE/CHANGE_DIRECTION'

export const CAHNAGE_PROFIT_ID = 'TRADE/CAHNAGE_PROFIT_ID'

export const SET_CURRENTPOSITION = 'TRADE/SET_CURRENTPOSITION'

export const SET_HOLD_ORDER_LINE = 'TRADE/SET_HOLD_ORDER_LINE'

export const CLEAR_HOLD_ORDER_LINE = 'TRADE/CLEAR_HOLD_ORDER_LINE'

export const SET_LAST_TIME = 'TRADE/SET_LAST_TIME'

export const SET_SIGNAL_LIST = 'TRADE/SET_SIGNAL_LIST'

export const SET_CURRENTPOSITION_LIST = 'TRADE/SET_CURRENTPOSITION_LIST'

export const SET_CURRENT_FUT_ACCOUNT = 'TRADE/SET_CURRENT_FUT_ACCOUNT'

export const SET_WEB_SOCKET_CONNECT_STATUS = 'TRADE/SET_WEB_SOCKET_CONNECT_STATUS'

export const SET_SUB_ACCOUNT_MESSAGE = 'TRADE/SET_SUB_ACCOUNT_MESSAGE'

export const SET_POOL_ITEM = 'TRADE/SET_POOL_ITEM'

export const SET_POOL_STOCK_LIST = 'TRADE/SET_POOL_STOCK_LIST'

export const CHANGE_STOCK_REGION_INFO = 'TRADE/CHANGE_STOCK_REGION_INFO'