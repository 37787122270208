const defaultState = {
  userInfo: { token: '' },
  notReadCount: 0,
  accountList: [],
  accountIndex: 0,
}

export { UPDATE_USER_ACCOUNT_LIST, UPDATE_USER_ACCOUNT_INDEX } from './actionTypes'
//reducer 可以接受state但是不可以修改state 只有store才能改变
//纯函数值得是，制定固定的输入，就一定会有固定的输出，而且不会有任何副作用
const reducer = (state = defaultState, action) => {
  // console.log(state, action)
  switch (action.type) {
    case "SAVEUSERINFO":
      return {
        ...state, userInfo: action.value
      }
    case "UPDATEREADCOUNT":
      return {
        ...state, notReadCount: action.value
      }
    case 'UPDATE_USER_ACCOUNT_LIST':
      return {
        ...state,accountList:action.value
      }
    case 'UPDATE_USER_ACCOUNT_INDEX':
      return {
        ...state,accountIndex:action.value
      }
    default:
      break
  }
  return state
}

export default reducer
