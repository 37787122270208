import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';  //BrowserRouter
import Bundle from './bundle';


const Index = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "Index" */'../pages/Index')}>
        {(Index) => <Index {...props} />}
    </Bundle>
);

const Home = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "Home" */'../pages/Home')}>
        {(Index) => <Index {...props} />}
    </Bundle>
);


const HomeStatistics =  (props) => (
    <Bundle load={() => import(/* webpackChunkName: "HomeStatistics" */'../pages/HomeStatistics')}>
        {(HomeStatistics) => <HomeStatistics {...props} />}
    </Bundle>
);

const HomeCustomStatistics = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "HomeCustomStatistics" */'../pages/HomeCustomStatistics/index')}>
        {(HomeCustomStatistics) => <HomeCustomStatistics {...props} />}
    </Bundle>
);

const TradeRoom = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "TradeRoom" */'../pages/TradeRoom')}>
        {(TradeRoom) => <TradeRoom {...props} />}
    </Bundle>
);

const TradeRoomNew = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "TradeRoomNew" */'../pages/TradeRoomNew')}>
        {(TradeRoomNew) => <TradeRoomNew {...props} />}
    </Bundle>
);

//合约池
const ContractPool = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "ContractPool" */'../pages/TradeContractPool/index')}>
        {(ContractPool) => <ContractPool {...props} />}
    </Bundle>
);

//创建合约池
const ContractPoolCreate = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "ContractPoolCreate" */'../pages/TradeContractPoolCreate/index')}>
        {(ContractPoolCreate) => <ContractPoolCreate {...props} />}
    </Bundle>
);

//首页跳至选股
const Pickstock  = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "Pickstock" */'../pages/Pickstock')}>
        {(Pickstock) => <Pickstock {...props} />}
    </Bundle>
);

//自定义选股
const PickstockCreate  = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "PickstockCreate" */'../pages/PickStockCreate')}>
        {(PickstockCreate) => <PickstockCreate {...props} />}
    </Bundle>
);

//选股结果
const PickstockResult = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "PickstockResult" */'../pages/StockResult')}>
        {(PickstockResult) => <PickstockResult {...props} />}
    </Bundle>
);

//选股报告
const PickstockDetail = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "PickstockDetail" */'../pages/PickstockDetail')}>
        {(PickstockDetail) => <PickstockDetail {...props} />}
    </Bundle>
);

//选股案例列表
const PickstockList = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "PickstockList" */'../pages/PickstockList')}>
        {(PickstockList) => <PickstockList {...props} />}
    </Bundle>
);

//策略详情
const StrategyDetail = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyDetail" */'../pages/StrategyDetail')}>
        {(StrategyDetail) => <StrategyDetail {...props} />}
    </Bundle>
);

//买卖信号 更多
const SignalList = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "SignalList" */'../pages/SignalList')}>
        {(SignalList) => <SignalList {...props} />}
    </Bundle>
);

//买卖信号详情
const SignalDetail =  (props) => (
    <Bundle load={() => import(/* webpackChunkName: "SignalDetail" */'../pages/SignalDetail')}>
        {(SignalDetail) => <SignalDetail {...props} />}
    </Bundle>
);

//策略
const Strategy =  (props) => (
    <Bundle load={() => import(/* webpackChunkName: "Strategy" */'../pages/Strategy')}>
        {(Strategy) => <Strategy {...props} />}
    </Bundle>
);

//编写策略
const StrategyEdit = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyEdit" */'../pages/StrategyEdit')}>
        {(StrategyEdit) => <StrategyEdit {...props} />}
    </Bundle>
);

//搭建策略
const StrategyBuild = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyBuild" */'../pages/StrategyBuilder')}>
        {(StrategyBuild) => <StrategyBuild {...props} />}
    </Bundle>
);

const StrategyBackTest = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyBackTest" */'../pages/StrategyBackTest')}>
        {(StrategyBackTest) => <StrategyBackTest {...props} />}
    </Bundle>
);

const StrategyBackCount = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyBackCount" */'../pages/StrategyBackCount')}>
        {(StrategyBackCount) => <StrategyBackCount {...props} />}
    </Bundle>
);

const StrategyPerformance = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyPerformance" */'../pages/StartegyPerformance')}>
        {(StrategyPerformance) => <StrategyPerformance {...props} />}
    </Bundle>
);

const StrategyCodeCompare = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyCodeCompare" */'../pages/StrategyCodeCompare')}>
        {(StrategyCodeCompare) => <StrategyCodeCompare {...props} />}
    </Bundle>
);

const StrategyPoolCreate = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyPoolCreate" */'../pages/StrategyPoolCreate')}>
        {(StrategyPoolCreate) => <StrategyPoolCreate {...props} />}
    </Bundle>
);

const StrategyPool = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StrategyPool" */'../pages/StrategyPool')}>
        {(StrategyPool) => <StrategyPool {...props} />}
    </Bundle>
);

const StartegySingleReport = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "StartegySingleReport" */'../pages/StragyeSingleReport')}>
        {(StartegySingleReport) => <StartegySingleReport {...props} />}
    </Bundle>
);

const TradeLog =  (props) => (
    <Bundle load={() => import(/* webpackChunkName: "TradeLog" */'../pages/TradeLog')}>
        {(TradeLog) => <TradeLog {...props} />}
    </Bundle>
);

const PickstockPush = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "PickstockPush" */'../pages/PickstockPush')}>
        {(PickstockPush) => <PickstockPush {...props} />}
    </Bundle>
);

const ScanAdd = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "ScanAdd" */'../pages/ScanAdd')}>
        {(ScanAdd) => <ScanAdd {...props} />}
    </Bundle>
);
const ScanList = (props) => (
    <Bundle load={() => import(/* webpackChunkName: "ScanList" */'../pages/PickstockScan')}>
        {(ScanList) => <ScanList {...props} />}
    </Bundle>
);

const UserCentre = (props) => (
    <Bundle load={() => import('../pages/User')}>
        {(User) => <User {...props} />}
    </Bundle>
);

const TapeAnalysis = (props) => (
    <Bundle load={() => import('../pages/HomeTapeAnalysis')}>
        {(TapeAnalysis) => <TapeAnalysis {...props} />}
    </Bundle>
);

const TapeDeepAnalysis = (props) => (
    <Bundle load={() => import('../pages/HomeTapeDeepAnalysis')}>
        {(TapeDeepAnalysis) => <TapeDeepAnalysis {...props} />}
    </Bundle>
);




const routerMap = [
    { path: '/', component: Index },
    // { path: '/:path', component: Index, exact: true },
    // { path: '*', component: Index },
];


const IndexRouterMap = [
    { path: '/', component: Home ,exact: true},
    { path: '/homeCustomStatistics', component: HomeCustomStatistics ,exact: true},
    { path: '/homeStatistics', component: HomeStatistics ,exact: true},
    { path: '/pickstock', component: Pickstock ,exact: true},
    { path: '/pickstockPush/:id', component: PickstockPush ,exact: true},
    { path: '/pickstock/create', component: PickstockCreate ,exact: true},
    { path: '/pickstock/result', component: PickstockResult ,exact: true},
    { path: '/tradeRoom', component: TradeRoom ,exact: true},
    { path: '/tradeRoomNew', component: TradeRoomNew ,exact: true},
    { path: '/tradeRoom/:symbol', component: TradeRoom, exact: true },
    { path: '/tradeRoomNew/:symbol', component: TradeRoomNew, exact: true },
    { path: '/tradeLog', component: TradeLog ,exact: true},
    { path: '/contractPool', component: ContractPool, exact: true },
    { path: '/createContractPool', component: ContractPoolCreate, exact: true },
    { path: '/pickstockDetail/:id', component: PickstockDetail, exact: true },
    { path: '/pickstockList', component: PickstockList, exact: true },
    { path: '/pickstockList/:id', component: PickstockList, exact: true },
    { path: '/signalList', component: SignalList, exact: true },
    { path: '/signalList/:id', component: SignalList, exact: true },
    { path: '/signalDetail/:id', component: SignalDetail, exact: true },
    { path: '/strategy', component: Strategy, exact: true },
    { path: '/strategyDetail/:id', component: StrategyDetail, exact: true },
    { path: '/strategyEdit', component: StrategyEdit, exact: true },
    { path: '/strategyEdit/:id', component: StrategyEdit, exact: true },
    { path: '/strategyBuild', component: StrategyBuild, exact: true },
    { path: '/strategyBuild/:id', component: StrategyBuild, exact: true },
    { path: '/strategyBacktest/:id', component: StrategyBackTest, exact: true },
    { path: '/strategyBackcount/:id', component: StrategyBackCount, exact: true },
    { path: '/strategyPerformance', component: StrategyPerformance, exact: true },
    { path: '/strategyCodeCompare', component: StrategyCodeCompare, exact: true },
    { path: '/createStrategyPool', component: StrategyPoolCreate, exact: true },
    { path: '/strategyPool', component: StrategyPool, exact: true },
    { path: '/strategy/singleReport/:index', component: StartegySingleReport, exact: true },
    { path: '/scan/scanAdd', component: ScanAdd, exact: true },
    { path: '/scan/scanList', component: ScanList, exact: true },
    { path: '/user/:id', component: UserCentre, exact: true },
    { path: '/tapeAnalysis', component: TapeAnalysis, exact: true },
    { path: '/tapeDeepAnalysis', component: TapeDeepAnalysis, exact: true },
   
];


export const IndexRouters = () => (
    <Switch>
        {IndexRouterMap.map((router) =>
            <Route key={router.path} exact={router.exact} path={router.path} component={router.component} />
        )}
    </Switch>
);



export const Routers = () => (
    <Router>
        <Route   component={Index} />
        {/* <Index /> */}
            {/* {routerMap.map((router) =>
                <Route key={router.path} exact={router.exact} path={router.path} component={router.component} />
            )} */}
       
    </Router>
);


