
import {createStore,combineReducers,applyMiddleware} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import index from './reducer';
import tradeRoomReducer from '../pages/TradeRoom/redux/reducer'

const reducer = combineReducers({
  home:index,
  tradeRoom:tradeRoomReducer
})

const middlewares = [thunkMiddleware];
if (process.env.NODE_ENV !== 'production') {
    // middlewares.push(immutable())
}

const storeEnhancers = process.env.NODE_ENV !== 'production' ?
    composeWithDevTools(
        applyMiddleware(...middlewares),
    ) :
    applyMiddleware(...middlewares);


const store = createStore(reducer,{},storeEnhancers)
export default store;